import styled from 'styled-components';
import FlexBox from '../FlexBox';

export const Container = styled.div<{
  bgColor?: string;
  opacity?: number;
}>`
  width: 100%;
  background-color: ${({ bgColor }) => bgColor || 'transparent'};
`;

export const BigContainer = styled.div<{ absoluteHeader?: boolean }>`
  width: 100%;
  position: ${({ absoluteHeader }) =>
    absoluteHeader ? 'absolute' : 'relative'};
  top: 0;
  left: 0;
  z-index: 2;
`;

export const ChildrenContainer = styled.div<{ isModal?: boolean }>`
  width: 100%;
  min-height: ${({ isModal }) => (isModal ? 'unset' : '100vh')};
  position: relative;
`;

export const FooterContainer = styled.div<{ hasBorderTop?: boolean }>`
  width: 100%;
  border-top: ${({ hasBorderTop }) =>
    hasBorderTop ? '1px solid #DBDBDB' : 'none'};
`;

export const Content = styled(FlexBox)`
  width: 100%;
  max-width: 1550px;
  margin: 0 auto;
  padding: 0 20px;
  box-sizing: border-box;
`;
