import React, { forwardRef, HTMLAttributes, useRef, useState } from 'react';

import Header from '@app/components/molecules/Header';
import Footer from '@app/components/molecules/Footer';
import MobileMenu from '@app/components/molecules/MobileMenu';

import * as Styled from './styles';

/**
 * Template Container
 */
export interface TemplateContainerProps extends HTMLAttributes<HTMLDivElement> {
  absoluteHeader?: boolean;
  isModal?: boolean;
  heroContent?: React.ReactNode;
  hideAuth?: boolean;
  wide?: boolean;
  bgColor?: string;
  headerSelected?: 'clients' | 'properties';
  headerSelectedIndex?: number;
  opacity?: number;
}

const TemplateContainer = forwardRef<any, TemplateContainerProps>(
  (
    {
      children,
      absoluteHeader,
      isModal,
      heroContent,
      hideAuth,
      wide,
      bgColor,
      headerSelected,
      headerSelectedIndex,
      opacity = 1,
      ...rest
    }: TemplateContainerProps,
    ref,
  ) => {
    const footerRef = useRef<any>(null);
    const [mobileMenu, openMobileMenu] = useState(false);

    return (
      <>
        {heroContent}
        <Styled.Container
          bgColor={bgColor}
          ref={ref}
          {...rest}
          opacity={opacity}
        >
          {!isModal && (
            <Styled.BigContainer
              absoluteHeader={!!heroContent || absoluteHeader}
            >
              <Header
                footerRef={footerRef}
                clickMenu={() => openMobileMenu(true)}
                lessMargin={!!heroContent}
                hasBgColor={false}
                hideAuth={hideAuth}
                wide={wide}
                headerSelected={headerSelected}
                headerSelectedIndex={headerSelectedIndex}
                opacity={opacity}
              />
            </Styled.BigContainer>
          )}

          <Styled.ChildrenContainer isModal>
            {children}
          </Styled.ChildrenContainer>

          {!isModal && (
            <>
              <Styled.FooterContainer hasBorderTop>
                <Footer footerRef={footerRef} wide={wide} />
              </Styled.FooterContainer>

              <MobileMenu
                visible={mobileMenu}
                closeMenu={() => openMobileMenu(false)}
                footerRef={footerRef}
              />
            </>
          )}
        </Styled.Container>
      </>
    );
  },
);

export default TemplateContainer;
